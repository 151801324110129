import React from 'react';

import Heading from '../../components/Heading';
import PrivacyPolicyDePage from '../../components/PrivacyPolicyDePage';
import PrivacyPolicyDePageBreadcrumb from '../../utils/seo/PrivacyPolicyDePageBreadcrumb';
import SEOContainer from '../Layout/SEO';
import Section from '../../components/Heading/Section';
import SectionFooterContactDefaultContainer from '../Section/SectionFooterContactDefault';
import buildContentPageUrl from '../../utils/url/buildContentPageUrl';
import routes from '../../config/routes';
import theme from '../../utils/styling/theme';

// Types

type Props = {};

const PrivacyPolicyDePageContainer = (_props: Props) => {
  const canonicalUrl = buildContentPageUrl(routes.privacyPolicyDe);

  const footer = <SectionFooterContactDefaultContainer backgroundColor={theme.color.grey} textColor={theme.color.white} />;

  return (
    <>
      {/* SEO */}
      <PrivacyPolicyDePageBreadcrumb />
      <SEOContainer canonicalUrl={canonicalUrl} title="Datenschutzerklärung" openGraphTitle="Datenschutzerklärung" />

      <Heading isVisuallyHidden={true}>Datenschutzerklärung</Heading>

      <Section hasNoTag={true}>
        <PrivacyPolicyDePage footer={footer} />
      </Section>
    </>
  );
};

export default PrivacyPolicyDePageContainer;
