import React from 'react';

import SectionStaticPageContent from '../Section/SectionStaticPageContent';
import StaticPageAddress from '../StaticPageItems/StaticPageAddress';
import StaticPageHeadline from '../StaticPageItems/StaticPageHeadline';
import StaticPageLanguageLink from '../StaticPageItems/StaticPageLanguageLink';
import StaticPageList from '../StaticPageItems/StaticPageList';
import StaticPageSection from '../StaticPageItems/StaticPageSection';
import StaticPageText from '../StaticPageItems/StaticPageText';
import StaticPageTextLink from '../StaticPageItems/StaticPageTextLink';
import StaticPageTitle from '../StaticPageItems/StaticPageTitle';
import routes from '../../config/routes';
import useGoogleAnalyticsOptOut from '../../utils/hooks/useGoogleAnalyticsOptOut';

import * as S from './styles';

// Types
import { ReactNode } from 'react';

type Props = {
  footer: ReactNode;
};

const PrivacyPolicyDePage = ({ footer }: Props) => {
  const { handleGoogleAnalyticsOptOut, isGoogleAnalyticsOptOutSet } = useGoogleAnalyticsOptOut();

  return (
    <>
      <SectionStaticPageContent isTitleSection={true} contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={25}>
        <StaticPageTitle>
          Privacy
          <br />
          Policy
        </StaticPageTitle>

        <StaticPageLanguageLink url={routes.privacyPolicyDe}>German Version: Datenschutzerklärung</StaticPageLanguageLink>

        <StaticPageLanguageLink url={routes.privacyPolicyEn}>English Version: Privacy Policy</StaticPageLanguageLink>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={50}>
        <StaticPageSection>
          <StaticPageHeadline level={1}>Datenschutzerklärung</StaticPageHeadline>

          <StaticPageSection>
            <StaticPageHeadline level={2}>1. Datenschutz auf einen Blick</StaticPageHeadline>

            <StaticPageSection>
              <StaticPageHeadline level={3}>Allgemeine Hinweise</StaticPageHeadline>

              <StaticPageText>
                Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie
                unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können.
                Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
              </StaticPageText>
            </StaticPageSection>
          </StaticPageSection>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={75}>
        <StaticPageSection>
          <StaticPageHeadline level={3}>Datenerfassung auf unserer Website</StaticPageHeadline>

          <StaticPageSection>
            <StaticPageHeadline level={4}>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</StaticPageHeadline>

            <StaticPageText>
              Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum
              dieser Website entnehmen.
            </StaticPageText>
          </StaticPageSection>

          <StaticPageSection>
            <StaticPageHeadline level={4}>Wie erfassen wir Ihre Daten?</StaticPageHeadline>

            <StaticPageText>
              Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie
              in ein Kontaktformular eingeben.
            </StaticPageText>

            <StaticPageText>
              Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten
              (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald
              Sie unsere Website betreten.
            </StaticPageText>
          </StaticPageSection>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={4}>Wofür nutzen wir Ihre Daten?</StaticPageHeadline>

          <StaticPageText>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur
            Analyse Ihres Nutzerverhaltens verwendet werden.
          </StaticPageText>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={4}>Welche Rechte haben Sie bezüglich Ihrer Daten?</StaticPageHeadline>
          <StaticPageText>
            Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen
            Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu
            sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns
            wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          </StaticPageText>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={50}>
        <StaticPageSection>
          <StaticPageHeadline level={3}>Analyse-Tools und Tools von Drittanbietern</StaticPageHeadline>

          <StaticPageText>
            Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit Cookies und mit
            sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu
            Ihnen zurückverfolgt werden. Sie können dieser Analyse widersprechen oder sie durch die Nichtbenutzung bestimmter Tools
            verhindern. Detaillierte Informationen dazu finden Sie in der folgenden Datenschutzerklärung. Sie können dieser Analyse
            widersprechen. Über die Widerspruchsmöglichkeiten werden wir Sie in dieser Datenschutzerklärung informieren.
          </StaticPageText>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={75}>
        <StaticPageSection>
          <StaticPageHeadline level={2}>2. Allgemeine Hinweise und Pflichtinformationen</StaticPageHeadline>

          <StaticPageSection>
            <StaticPageHeadline level={3}>Datenschuz</StaticPageHeadline>

            <StaticPageText>
              Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
              vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie diese
              Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
              persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
              sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht. Wir weisen darauf hin, dass die Datenübertragung im
              Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem
              Zugriff durch Dritte ist nicht möglich.
            </StaticPageText>
          </StaticPageSection>

          <StaticPageSection>
            <StaticPageHeadline level={3}>Hinweise zur verantwortlichen Stelle</StaticPageHeadline>

            <StaticPageText>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</StaticPageText>

            <StaticPageAddress>
              Jan Kath Design GmbH
              <br />
              Friederikastr. 148
              <br />
              44789 Bochum
              <br />
              Telefon: +49 234 9412344
              <br />
              E-Mail: <StaticPageTextLink url="mailto:info@jan-kath.com">info@jan-kath.com</StaticPageTextLink>
            </StaticPageAddress>

            <StaticPageText>
              Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und
              Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
            </StaticPageText>
          </StaticPageSection>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start wrap" contentPositionY="flex-start wrap" sectionWidth={75}>
        <StaticPageSection>
          <StaticPageHeadline level={3}>Widerruf ihrer Einwilligung zur Datenverarbeitung</StaticPageHeadline>
          <StaticPageText>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte
            Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum
            Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
          </StaticPageText>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={3}> Beschwerderecht bei der zuständigen Aufsichtsbehörde</StaticPageHeadline>
          <StaticPageText>
            Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
            Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser
            Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link entnommen
            werden:{' '}
            <S.ComplaintLink url="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">
              https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
            </S.ComplaintLink>
            .
          </StaticPageText>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={3}>Recht auf Datenübertragbarkeit</StaticPageHeadline>

          <StaticPageText>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten,
            an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte
            Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
          </StaticPageText>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={75}>
        <StaticPageSection>
          <StaticPageHeadline level={3}>SSL- bzw. TLS-Verschlüsselung</StaticPageHeadline>

          <StaticPageText>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
            erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer
            Browserzeile.
          </StaticPageText>

          <StaticPageText>
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
            werden
          </StaticPageText>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={3}>Auskunft, Sperrung, Löschung</StaticPageHeadline>

          <StaticPageText>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie
            sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
          </StaticPageText>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={3}>Widerspruch gegen Werbe-Mails</StaticPageHeadline>

          <StaticPageText>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich
            angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich
            ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
          </StaticPageText>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={50}>
        <StaticPageSection>
          <StaticPageHeadline level={2}>3. Datenschutzbeauftragter</StaticPageHeadline>

          <StaticPageSection>
            <StaticPageHeadline level={3}>Gesetzlich vorgeschriebener Datenschutzbeauftragter</StaticPageHeadline>

            <StaticPageText>Wir haben für unser Unternehmen einen Datenschutzbeauftragten bestellt.</StaticPageText>

            <StaticPageAddress>
              Jan Kath Design GmbH
              <br />
              Charlotte Kath
              <br />
              Friederikastr. 148
              <br />
              44789 Bochum
              <br />
              Telefon: +49 234 9412344
              <br />
              E-Mail: <StaticPageTextLink url="mailto:datenschutz@jan-kath.com">datenschutz@jan-kath.com</StaticPageTextLink>
            </StaticPageAddress>
          </StaticPageSection>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={75}>
        <StaticPageSection>
          <StaticPageHeadline level={2}>4. Datenerfassung auf unserer Website</StaticPageHeadline>

          <StaticPageSection>
            <StaticPageHeadline level={3}>Cookies</StaticPageHeadline>

            <StaticPageText>
              Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten
              keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine
              Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Die meistenDie meisten der von uns
              verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere
              Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim
              nächsten Besuch wiederzuerkennen. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert
              werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
              das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die
              Funktionalität dieser Website eingeschränkt sein. Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder
              zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf
              Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung
              von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies zur
              Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.
            </StaticPageText>
          </StaticPageSection>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={75}>
        <StaticPageSection>
          <StaticPageHeadline level={3}>Server-Log-Dateien</StaticPageHeadline>

          <StaticPageText>
            Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser
            automatisch an uns übermittelt.
          </StaticPageText>

          <StaticPageText>Dies sind:</StaticPageText>

          <StaticPageList>
            <li>Browsertyp und Browserversion</li>
            <li>verwendetes Betriebssystem</li>
            <li>Referrer URL</li>
            <li>Hostname des zugreifenden Rechners</li>
            <li>Uhrzeit der Serveranfrage</li>
            <li>IP-Adresse</li>
          </StaticPageList>

          <StaticPageText>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</StaticPageText>

          <StaticPageText>
            Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags
            oder vorvertraglicher Maßnahmen gestattet.
          </StaticPageText>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={3}>Registrierung auf dieser Website</StaticPageHeadline>

          <StaticPageText>
            Sie können sich auf unserer Website registrieren, um zusätzliche Funktionen auf der Seite zu nutzen. Die dazu eingegebenen Daten
            verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes, für den Sie sich registriert haben. Die bei der
            Registrierung abgefragten Pflichtangaben müssen vollständig angegeben werden. Anderenfalls werden wir die Registrierung
            ablehnen. Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch notwendigen Änderungen nutzen wir die bei der
            Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu informieren. Die Verarbeitung der bei der Registrierung
            eingegebenen Daten erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können eine von Ihnen erteilte
            Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bereits
            erfolgten Datenverarbeitung bleibt vom Widerruf unberührt. Die bei der Registrierung erfassten Daten werden von uns gespeichert,
            solange Sie auf unserer Website registriert sind und werden anschließend gelöscht. Gesetzliche Aufbewahrungsfristen bleiben
            unberührt.
          </StaticPageText>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={50}>
        <StaticPageSection>
          <StaticPageHeadline level={2}>5. Analyse Tools und Werbung</StaticPageHeadline>

          <StaticPageSection>
            <StaticPageHeadline level={3}>Google Analytics</StaticPageHeadline>

            <StaticPageSection>
              <StaticPageText>
                Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre
                Parkway, Mountain View, CA 94043, USA.
              </StaticPageText>

              <StaticPageText>
                Google Analytics verwendet so genannte &#34;Cookies&#34;. Das sind Textdateien, die auf Ihrem Computer gespeichert werden
                und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre
                Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
              </StaticPageText>

              <StaticPageText>
                Die Speicherung von Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat
                ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu
                optimieren.
              </StaticPageText>
            </StaticPageSection>
          </StaticPageSection>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={75}>
        <StaticPageSection>
          <StaticPageHeadline level={4}>Browser Plugin</StaticPageHeadline>

          <StaticPageText>
            Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie
            jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen
            können. Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten
            (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem
            folgenden Link verfügbare Browser-Plugin herunterladen und installieren:{' '}
            <StaticPageTextLink url="https://tools.google.com/dlpage/gaoptout?hl=de">
              https://tools.google.com/dlpage/gaoptout?hl=de
            </StaticPageTextLink>
            .
          </StaticPageText>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={4}>Widerspruch gegen Datenerfassung</StaticPageHeadline>

          <StaticPageText>
            Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein
            Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website verhindert:{' '}
            <button onClick={handleGoogleAnalyticsOptOut} type="button">
              Google Analytics deaktivieren {isGoogleAnalyticsOptOutSet && '✔'}
            </button>
            .
          </StaticPageText>

          <StaticPageText>
            Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google:{' '}
            <StaticPageTextLink url="https://support.google.com/analytics/answer/6004245?hl=de">
              https://support.google.com/analytics/answer/6004245?hl=de
            </StaticPageTextLink>
            .
          </StaticPageText>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={4}>Auftragsdatenverarbeitung</StaticPageHeadline>

          <StaticPageText>
            Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung abgeschlossen und setzen die strengen Vorgaben der deutschen
            Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.
          </StaticPageText>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={75}>
        <StaticPageSection>
          <StaticPageHeadline level={2}>6. Plugins und Tools</StaticPageHeadline>

          <StaticPageSection>
            <StaticPageHeadline level={3}>Google Maps</StaticPageHeadline>

            <StaticPageSection>
              <StaticPageText>
                Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway,
                Mountain View, CA 94043, USA.
              </StaticPageText>

              <StaticPageText>
                Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse zu speichern. Diese Informationen werden in der
                Regel an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss
                auf diese Datenübertragung.
              </StaticPageText>

              <StaticPageText>
                Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und an einer
                leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von
                Art. 6 Abs. 1 lit. f DSGVO dar.
              </StaticPageText>

              <StaticPageText>
                Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google:{' '}
                <StaticPageTextLink url="https://www.google.de/intl/de/policies/privacy/">
                  https://www.google.de/intl/de/policies/privacy/
                </StaticPageTextLink>
                .
              </StaticPageText>
            </StaticPageSection>
          </StaticPageSection>
        </StaticPageSection>
      </SectionStaticPageContent>

      {footer}
    </>
  );
};

export default PrivacyPolicyDePage;
