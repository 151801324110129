import React from 'react';
import { Generic, JSONLD } from 'react-structured-data';

import buildBreadcrumbListItem from './buildBreadcrumbListItem';
import routes from '../../config/routes';
import { STRUCTURED_DATA_HOMEPAGE_BREADCRUMB_ITEM } from './constants';

const PrivacyPolicyDePageBreadcrumb = () => {
  const privacyPolicyDePageListItem = buildBreadcrumbListItem({ name: 'Datenschutzerklärung', position: 2, url: routes.privacyPolicyDe });

  const schema = {
    itemListElement: [STRUCTURED_DATA_HOMEPAGE_BREADCRUMB_ITEM, privacyPolicyDePageListItem],
  };

  return (
    <JSONLD dangerouslyExposeHtml={true}>
      <Generic jsonldtype="BreadcrumbList" schema={schema} />
    </JSONLD>
  );
};

export default PrivacyPolicyDePageBreadcrumb;
