import React from 'react';

import LayoutContainer from '../containers/Layout';
import PrivacyPolicyDePageContainer from '../containers/PrivacyPolicyDePage';

const PrivacyPolicyDePagePage = () => {
  return (
    <LayoutContainer>
      <PrivacyPolicyDePageContainer />
    </LayoutContainer>
  );
};

export default PrivacyPolicyDePagePage;
